import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { Subject, distinctUntilChanged, filter, map } from 'rxjs';

import { NetworkStatusService } from './network-status.service';
import { StorageService } from './storage/storage.service';
import { ActiveUserService } from './active-user.service';
import { environment } from 'src/environments/environment';

export type AnalyticsEventType =
    'download' |
    'email' |
    'page-view' |
    'preview' |
    'product-modal' |
    'web-link' |
    'zoom';

export type AnalyticsEventTagType =
    'brochure' |
    'color-configurator' |
    'gallery' |
    'product-modal' |
    'sellsheet' |
    'three-sixty' |
    'video';

export interface AnalyticsEvent {
    type: AnalyticsEventType;
    tags?: AnalyticsEventTagType[]; // Add more detail about the event type
    path?: string; // Entire path that the page is on
    data?: any; // Any additional analytics data for a specific event
    userEmail?: string;
    userRegion?: string;
    date?: string;
    expireAt?: number;
    env?: string;
    site?: string;
}

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private events$: Subject<AnalyticsEvent | AnalyticsEventType>;

    constructor(
        private storage: StorageService,
        private userService: ActiveUserService,
        private location: Location,
        private network: NetworkStatusService,
        private http: HttpClient
    ) {
        this.network.isOnline$.subscribe(async (isOnline) => {
            if (isOnline) {
                this.sendAnalyticsEvents();
            }
        });

        this.events$ = new Subject();
        this.events$.pipe(
            map((event: AnalyticsEvent | AnalyticsEventType) => {
                let _event = event;

                if (typeof _event === 'string') {
                    _event = { type: _event };
                }

                return this.populateEvent(_event);
            }),
            filter(event => !!event.path),
            distinctUntilChanged(
                // Prevent sending events where multiple routers' NavigationEnds create new events of the same page-view
                (prev: AnalyticsEvent, curr: AnalyticsEvent) => {
                    if (prev.type === 'page-view' && curr.type === 'page-view') {
                        return prev.path === curr.path;
                    }
                }
            ),
        ).subscribe((event) => this.send(event));
    }

    track(event: AnalyticsEvent | AnalyticsEventType): void {
        this.events$.next(event);
    }

    private async send(event: AnalyticsEvent): Promise<void> {
        if (this.network.isOnline) {
            this.sendAnalyticsEvents([event]);
        } else {
            // Store events to send once online again
            const events = await this.getStoredEvents();
            events.push(event);
            await this.storage.set('analytics-events', JSON.stringify(events));
        }
    };

    private async getStoredEvents(): Promise<AnalyticsEvent[]> {
        const events = await this.storage.get('analytics-events');
        return events ? JSON.parse(events) : [];
    }

    private async sendAnalyticsEvents(events?: AnalyticsEvent[]): Promise<void> {
        const _events = events || await this.getStoredEvents();

        if (_events.length) {
            if (environment.analyticsEndpoint) {
                this.http.post(environment.analyticsEndpoint, _events).subscribe();
            }

            await this.storage.remove('analytics-events');
        }
    }

    private populateEvent(event: AnalyticsEvent): AnalyticsEvent {
        const date = event.date ? event.date : new Date();
        const expireAt = (() => {
            const date = new Date();
            const monthsToRetain = 13;
            return new Date(new Date(date).setMonth(date.getMonth() + monthsToRetain)).getTime();
        })();

        return {
            ...event,
            path: event.path ? event.path : this.location.path(),
            tags: event.tags ? event.tags : [],
            data: event.data ? event.data : {},
            userEmail: event.userEmail ? event.userEmail : this.userService.currentUserValue.email,
            userRegion: event.userRegion ? event.userRegion : this.userService.currentUserValue.user_metadata.region,
            date: (date as Date).toISOString(),
            expireAt,
            env: environment.name,
            site: 'mea'
        };
    }
}
